import moment from "moment";
import CommonUtil from "../shared/CommonUtil";


// export const baseurl = 'https://newdev.myleadingcampus.com/api';
export const baseurl = "https://prod1.myleadingcampus.com/api";
// export const baseurl = "http://localhost:8081/api";


let refreshTokenApiInProgress = false;


export const refreshRequest = async function (obj) {
    refreshTokenApiInProgress = true;

    return await fetch(baseurl + obj.url, {
        method: obj.method, // *GET, POST, PUT, DELETE, etc
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            "authorization": 'Bearer ' + CommonUtil.getLocalStorage('refresh_token')
        }
    })
        .then(res => { if (res.status === 204) { return {} } else { return res.json() } })
        .then(
            (result) => {
                if (!result.code) {
                    result.code = 200;
                }
                return result;
            },
            (error) => {
                console.log(error);
                error.type = "error";
                return error;
            }
        )
}

const apiService = async function (obj) {
    // console.log(CommonUtil.getLocalStorage('refresh_token_exp_time'))

    if (!CommonUtil.getLocalStorage('refresh_token_exp_time') ||
        CommonUtil.getLocalStorage('refresh_token_exp_time') === "null" ||
        moment(CommonUtil.getLocalStorage('refresh_token_exp_time')).diff(moment(), "seconds") <= 0) {
        if (window.location.pathname === '/' || window.location.pathname === '/login' ||
            window.location.pathname === '/forgot-password' || window.location.pathname === '/reset-password') {
        } else {
            window.location.pathname = '/login';
            localStorage.clear();
            // alert("Session expired. Please login again(check 1)")
            return ({ message: 'Session expired. Please login again' });
        }

    }


    if (!refreshTokenApiInProgress && CommonUtil.getLocalStorage('access_token_exp_time') && moment(CommonUtil.getLocalStorage('access_token_exp_time')).diff(moment(), "seconds") <= 0) {
        let resp = await refreshRequest({
            url: "/auth/refresh-token",
            method: 'get'
        });
        if (resp && resp.code === 200) {
            CommonUtil.setLocalStorage('access_token', resp?.data?.access.token);
            CommonUtil.setLocalStorage('access_token_exp_time', resp?.data?.access.expires);
            CommonUtil.setLocalStorage('refresh_token', resp?.data?.refresh.token);
            CommonUtil.setLocalStorage('refresh_token_exp_time', resp?.data?.refresh.expires);
            refreshTokenApiInProgress = false;
            if (obj.url.indexOf('logout') === -1) {
                obj.Authorization = 'Bearer ' + resp?.data?.access.token;
            }
        } else {
            return ({ message: 'Session expired. Please login again' });
        }
    }
    let isdownload = false;
    let filenameForDownload = '';
    // let isFileDownloadError = false;
    if (obj.params && Object.keys(obj.params).length > 0) {
        if (obj.params.hasOwnProperty('download') || obj.params.hasOwnProperty('isDownload')) {
            isdownload = true;
            filenameForDownload = obj.params.fileName + '.xlsx';
            delete obj.params.fileName;
        }

        let qs
        // obj.url = new
        if (obj.duplicateParams) {
            obj.url = obj.url + "?" + obj.duplicateParams;
        } else {
            qs = Object.keys(obj.params).reduce(function (_qs, k, i) { return _qs + '&' + k + '=' + obj.params[k]; }, '').substring(1);
            obj.url = obj.url + "?" + qs;
        }
    }

    let body = obj.data;
    for (let key in body) {
        if (body[key] instanceof Date && !isNaN(body[key])) {
            body[key] = CommonUtil.formatDate(body[key]);
        }
    }

    let settings = {
        method: obj.method, // *GET, POST, PUT, DELETE, etc
        credentials: 'include',
        headers: {
            'Content-Type': obj.content_type ? obj.content_type : 'application/json',
            "module_id": obj.module_id ? obj.module_id : "",
            "passaccess": obj.passaccess ? obj.passaccess : false,
            "authorization": obj.Authorization ? obj.Authorization : 'Bearer ' + CommonUtil.getLocalStorage('access_token'),
        },
        body: JSON.stringify(body),// body data type must match "Content-Type" header
        // credentials: 'same-origin'
    }

    if (obj.isFormData) {
        delete settings['headers']['Content-Type'];
        settings['body'] = obj.data;
    }

    const contentType = obj.content_type ? obj.content_type : 'application/json';

    return await fetch(baseurl + obj.url, settings)
        .then(res => {
            if (res.status === 204) {
                return {};
            } else {
                if (obj.params?.hasOwnProperty("html")) {
                    return res.text();
                }
                if (obj.content_type === 'text/html') {
                    return res.text();
                }
                if (isdownload || obj?.isDownload || obj.params?.hasOwnProperty("isFeesDueDownload") || obj.params?.hasOwnProperty("isDetailedreport") || obj.params?.hasOwnProperty("exportType") || obj.params?.hasOwnProperty("download") || obj?.url?.includes('download') || obj?.url?.includes('export') || obj?.url?.includes('detailedReport') || contentType !== "application/json") {
                    if (res.status === 200 || res.status === 201) {
                        return res.blob();
                    } else {
                        return res.json().then((data) => {
                            return {
                                code: res.status,
                                data: data
                            }
                        });
                    }
                }
                else {
                    return res.json().then((data) => {
                        const resp = {
                            data: data,
                        }
                        if (res.status === 200 || res.status === 201) {
                            resp.code = 200;
                        } else {
                            resp.code = res.status;
                        }
                        return resp;
                    });
                }
            }
        })
        .then(
            (result) => {
                if (result.code === 200 && isdownload) {
                    let href = window.URL.createObjectURL(result);
                    const a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";
                    a.href = href;
                    a.download = filenameForDownload;
                    a.click();
                    return { code: 200 };
                }

                if (result.code === 401) {
                    localStorage.clear();
                    if (window.location.pathname !== '/') {
                        window.location.pathname = '/';
                    }
                }
                if (!result.code && obj.content_type !== 'text/html' && !obj.params?.hasOwnProperty("html")) {
                    result.code = 200;
                }
                return result;
            },
            (error) => {
                error.type = "error";
                return error;

            }
        )
}

export default apiService;
