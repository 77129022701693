import dayjs from "dayjs";
import moment from "moment";
import toast from "react-hot-toast";
import { getFileAPI } from "src/api/commonService";

function getBusinessName() {
    let bname = sessionStorage.getItem("businessName");
    if (bname) {
        return bname;
    }
}

function jwtDecode(t) {
    let token = {};
    token.payload = JSON.parse(window.atob(t.split('.')[1]));
    return (token.payload)
}

const formatDateToReadable = (date) => {
    return moment(date).format('DD-MM-YYYY')
}

const formatToDayjs = (value, isDate = true) => {
    if (!isDate) {
        return dayjs(value, 'HH:mm:ss')
    }
    return dayjs(moment(value).format('YYYY-MM-DD'), 'YYYY-MM-DD')
}

function checkIfValidEmail(email) {
    return /(.+)@(.+){2,}\.(.+){2,}/.test(email);
}

function checkIfStaging() {
    return window.location.href.indexOf('localhost') > -1;
}

function setStorage(key, value) {
    sessionStorage.setItem(key, value);
}

function getStorage(key) {
    return sessionStorage.getItem(key);
}

function setLocalStorage(key, value) {
    localStorage.setItem(key, value);
}

function getLocalStorage(key) {
    return localStorage.getItem(key);
}

// function checkIfValidEmail(email) {
//     const pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
//     console.log(email.match(pattern));
//     return email.match(pattern);
// }

function getSelectionStart(o) {
    if (o.createTextRange) {
        const r = document.selection.createRange().duplicate()
        r.moveEnd('character', o.value.length)
        if (r.text === '') return o.value.length
        return o.value.lastIndexOf(r.text)
    } else return o.selectionStart
}

function validateDecimalValue(evt) {
    const el = evt.target;
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    const number = el.value.split('.');
    if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    //just one dot (thanks ddlab)
    if (number.length > 1 && charCode === 46) {
        return false;
    }
    //get the carat position
    const caratPos = this.getSelectionStart(el);
    const dotPos = el.value.indexOf(".");
    if (caratPos > dotPos && dotPos > -1 && (number[1].length > 1)) {
        return false;
    }
    return true;
}

function setJsonLocalStorage(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
}

function getJsonLocalStorage(key) {
    const data = localStorage.getItem(key);
    if (data) {
        return JSON.parse(data);
    } else {
        return {};
    }
}

function getSelectedSchool() {
    const data = localStorage.getItem('selectedSchool');
    if (data) {
        return JSON.parse(data)['id'];
    } else {
        return '-';
    }
}
function getSelectedSession() {
    const data = localStorage.getItem('selectedSession');
    if (data) {
        return JSON.parse(data);
    } else {
        return '-';
    }
}
function getSelectedSchoolLogo() {
    const data = localStorage.getItem('selectedSchoolLogo');
    if (data) {
        return JSON.parse(data);
    } else {
        return '-';
    }
}


const formatDate = (date) => {
    return moment(date).format().split('T')[0];
}

const calculateTimeAgo = (time) => {
    const now = moment();
    const entryTime = moment(time);
    const diffInSeconds = now.diff(entryTime, 'seconds');
    if (diffInSeconds < 60) {
        return (`${diffInSeconds} seconds ago`);
    } else if (diffInSeconds < 3600) {
        const diffInMinutes = Math.floor(diffInSeconds / 60);
        return (`${diffInMinutes} minutes ago`);
    } else if (diffInSeconds < 86400) {
        const diffInHours = Math.floor(diffInSeconds / 3600);
        const diffInMinutes = Math.floor((diffInSeconds % 3600) / 60);
        return (`${diffInHours} hours ${diffInMinutes} minutes ago`);
    } else {
        const diffInDays = Math.floor(diffInSeconds / 86400);
        const diffInHours = Math.floor((diffInSeconds % 86400) / 3600);
        return (`${diffInDays} days ${diffInHours} hours ago`);
    }
};

const calculateTimetoDelete = (createdAt) => {
    try {
        const isPremium = getLocalStorage("isPremium") === "true" ? true : false;
        if (isPremium) return null
        else {
            const createdAtDate = moment(createdAt?.split('T')[0]);
            const currentDate = moment();
            const daysDifference = currentDate.diff(createdAtDate, 'days');
            const daysToDelete = 30 - daysDifference;
            if (daysToDelete < 0) return `This file has been deleted.`
            return `This file will be deleted in ${daysToDelete} days.`
        }
    } catch (error) {
        return null;
    }
}

const isConfirmed = (message = "Are you sure, You want to delete this record ?") => {
    return window.confirm(message);
};

const openInNewTab = (page) => {
    const newWindow = window.open();
    newWindow.document.open();
    newWindow.document.write(page);
    newWindow.document.close();
}

const isCollege = () => {
    return localStorage.getItem('isSchool') === "false";
}


const getFile = async (fileName, isFeeReciept, localStorageName, download, setState) => {
    if (isFeeReciept) {
        try {
            const resp = await getFileAPI(`payments/${fileName}.pdf`)
            if (resp?.code === 200) {
                if (resp?.data?.url) {
                    let link = document.createElement('a');
                    link.href = resp?.data?.url;
                    link.target = "_blank";
                    link.click();
                } else {
                    toast.error("File has been deleted, Please contact admin for more information.");
                }
                return resp?.data?.url
            } else {
                const result = await getFileAPI(`school-name/${fileName}.pdf`);
                if (result?.code === 200) {
                    let link = document.createElement('a');
                    link.href = result?.data?.url;
                    link.click();
                    console.log(result?.data?.url);
                    return result?.data?.url
                } else {
                    // toast.error(resp?.data?.message);
                    throw new Error(resp?.data?.message);
                }
            }
        } catch (error) {
            console.log(error);
        }
    } else {
        try {
            const resp = await getFileAPI(fileName)
            if (resp?.code === 200) {
                if (localStorageName) {
                    localStorage.setItem(localStorageName, resp?.data?.url);
                }
                if (download) {
                    if (resp?.data?.url) {
                        let link = document.createElement('a');
                        link.target = "_blank";
                        link.href = resp?.data?.url;
                        link.click();
                    } else {
                        toast.error("File has been deleted, Please contact admin for more information.");
                    }
                }
                if (setState) {
                    setState(resp?.data?.url);
                }
                return resp?.data?.url
            } else {
                // toast.error(resp?.data?.message);
            }
        } catch (error) {
            console.log(error);
        }
    }

}


function convertToAMPM(time24) {
    // Split the time into hours and minutes
    try {
        if (time24.includes("AM") || time24.includes("PM")) {
            return time24
        }
        const timeParts = time24.split(":");
        let hours = parseInt(timeParts[0]);
        let minutes = parseInt(timeParts[1]);

        // Determine if it's AM or PM
        const period = hours >= 12 ? "PM" : "AM";

        // Adjust hours if necessary
        hours = hours % 12;
        hours = hours ? hours : 12; // Handle midnight (0 hours)

        // Format the time in AM/PM format
        const time12 = hours + ":" + (minutes < 10 ? "0" : "") + minutes + " " + period;

        return time12;
    } catch (error) {
        return ""
    }
}

const checkValidity = (obj) => {
    for (let key in obj) {
        if (!obj[key]) {
            return false
        }
    }
    return true
}

const testPatterns = {
    phone: /^\d{10}$/,
    email: /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/,
    decimal: /^\d+(\.\d{1,2})?$/,
    integer: /^\d+$/,
    gst: /^[0-9A-Za-z]{15}$/
}

const mdsIds = [
    "MTA3MTZiNDk0NjFiYTAxZTFlZTY3YmJiYmJjMDI5MDE=",
    "ZDdkNTM4YmJhZDIzZWJjYTIwOWNkNDlkNjc0YjRiMzk=",
    "YWMyMjlkNjZlMzQyYjVlZGVmOTQwYTM3MTNlNDU2N2E",
    "MzIxMjJkMDExYzJhYmMzNGY3MzUxYTViYzkxZmEwZWI=",
    "NmViMTYwY2NmZDBkNDU0N2ViOTk0YjdiMDllZTY2NjY=",
    "ZjZkM2NiZDU5NGZiNDY1NThiMGFlODhmZTY5MTU1MWI=",
    "YWMyMjlkNjZlMzQyYjVlZGVmOTQwYTM3MTNlNDU2N2E="
]

const isVikramShila = () => getSelectedSchool() === "YTcwMTIyZmU1YzdlM2ZmYTU1MjNkM2MyZWY5ZmUyMmE="

const isSuperAdmin = getLocalStorage("isSuperAdmin") === "1";

const grades = mdsIds?.includes(getSelectedSchool()) ? "A,B,C,D,E" : "A+,A,B+,B,C+,C,D+,D,E,F"

const showmedicalInfo = () => (isSuperAdmin || mdsIds?.includes(getSelectedSchool())) ? true : false
const showRectorInfo = () => (isSuperAdmin || mdsIds?.includes(getSelectedSchool())) ? true : false


const CommonUtil = {
    getBusinessName,
    checkIfValidEmail,
    checkIfStaging,
    setStorage,
    getStorage,
    setLocalStorage,
    getLocalStorage,
    validateDecimalValue,
    getSelectionStart,
    setJsonLocalStorage,
    getJsonLocalStorage,
    getSelectedSchool,
    getSelectedSchoolLogo,
    jwtDecode,
    formatDate,
    getSelectedSession,
    calculateTimeAgo,
    isConfirmed,
    openInNewTab,
    isCollege,
    getFile,
    convertToAMPM,
    checkValidity,
    testPatterns,
    calculateTimetoDelete,
    mdsIds,
    grades,
    showmedicalInfo,
    isSuperAdmin,
    formatDateToReadable,
    formatToDayjs,
    isVikramShila,
    showRectorInfo
}

export default CommonUtil;
